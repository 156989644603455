import React from "react";
import StylishBorder from "./../../../../Assets/images/New York/Slider/line-black.png";
import Star from "./../../../../Assets/images/New York/Slider/str.svg";
import GoogleReview from "./../../../../Assets/images/New York/Slider/google-reviews.jpg";
import YelpReview from "./../../../../Assets/images/New York/Slider/yelp-reviews.jpg";
import IvouchReview from "./../../../../Assets/images/New York/ivouch-reviews.jpg";
import ReactOwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { ArrowBackIos, ArrowRightAlt } from "@material-ui/icons";
import { FaQuoteLeft } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import { Rating } from "@mui/material";

const WhatPeopleSay = () => {
  return (
    <>
      <section className="testimonials">
        <div className="container">
          <div className="title-box text-center pb-4">
            <h2>WHAT PEOPLE SAY</h2>
            <img src={StylishBorder} alt="" />
          </div>

          <div className="slider-wrapper">
            <Tabs
              defaultActiveKey="google"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="google" title="">
                <div className="google-review review-block">
                  <div className="header text-center">
                    <img src={GoogleReview} alt="" />
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      className="owl-main owl-theme"
                      nav={true}
                      dots={true}
                      navText={[ArrowBackIos, ArrowRightAlt]}
                      items={1}
                      loop
                    >
                      <div className="item">
                        <p>
                          <span>
                            Excellent jewelry selection, customer service and
                            price. Purchased diamond wedding bands a decade ago
                            and have been pleased with the quality. Would highly
                            recommend for all jewelery needs including re-sizing
                            and custom orders. Will certainly repeat business.
                          </span>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=magnolia+jewelers&oq=magnolia+&aqs=chrome.0.69i59j69i57j46i199i433i465i512j46i131i433i512j0i433i650j69i60j69i61j69i60.8975j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8628e17071efa477:0xcfc61c9a6596489c,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.1" defaultValue={5} precision={1.1} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=magnolia+jewelers&oq=magnolia+&aqs=chrome.0.69i59j69i57j46i199i433i465i512j46i131i433i512j0i433i650j69i60j69i61j69i60.8975j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8628e17071efa477:0xcfc61c9a6596489c,1,,,,"
                          >
                            J. Jones
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            I have been going to Magnolia Jewelers for 5 years now.
                            They are very professional. I've had many repairs
                            done by them. Today they put my new watch band on my
                            watch for free. I will be returning to them soon.
                            Very courteous.
                          </span>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=magnolia+jewelers&oq=magnolia+&aqs=chrome.0.69i59j69i57j46i199i433i465i512j46i131i433i512j0i433i650j69i60j69i61j69i60.8975j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8628e17071efa477:0xcfc61c9a6596489c,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="5" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            to="https://www.google.com/search?q=magnolia+jewelers&oq=magnolia+&aqs=chrome.0.69i59j69i57j46i199i433i465i512j46i131i433i512j0i433i650j69i60j69i61j69i60.8975j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8628e17071efa477:0xcfc61c9a6596489c,1,,,,"
                          >
                            Theresa Resch
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            The best place to buy jewelry. I've been coming to
                            this place for over 20 years and they always have
                            the best prices and quality. Wedding rugs shopping?
                            Go here fellas!
                          </span>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=magnolia+jewelers&oq=magnolia+&aqs=chrome.0.69i59j69i57j46i199i433i465i512j46i131i433i512j0i433i650j69i60j69i61j69i60.8975j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8628e17071efa477:0xcfc61c9a6596489c,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.3" defaultValue={5} precision={1.3} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=magnolia+jewelers&oq=magnolia+&aqs=chrome.0.69i59j69i57j46i199i433i465i512j46i131i433i512j0i433i650j69i60j69i61j69i60.8975j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8628e17071efa477:0xcfc61c9a6596489c,1,,,,"
                          >
                            Cammron Hollimon
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            The family oriented staff and reasonable pricing is
                            the reason it's our "go to" place for fine
                            jewelry....even on not special occasions!!
                          </span>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=magnolia+jewelers&oq=magnolia+&aqs=chrome.0.69i59j69i57j46i199i433i465i512j46i131i433i512j0i433i650j69i60j69i61j69i60.8975j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8628e17071efa477:0xcfc61c9a6596489c,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.4" defaultValue={5} precision={1.4} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="#"
                          >
                            Estella Cohen
                          </a>
                        </h3>
                      </div>
                    </ReactOwlCarousel>
                  </div>
                </div>
              </Tab>

            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatPeopleSay;
