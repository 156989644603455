import React from "react";
import { useState, useEffect } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import StylishBorder from "./../../../../Assets/images/New York/Slider/line-black.png";
import ReactOwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { base_url } from "../../../../Helpers/request";
import axios from "axios";

const BestSellingProduct = () => {
  const [bestSellingData, setBestSellingData] = useState([]);

  const getData = async () => {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    await axios.get(base_url + "/common/bestseller", options).then((res) => {
      if (res.data.status == 1) {
        setBestSellingData(res?.data?.data);
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="best_selling_product">
        <Container>
          <div className="title-box text-center pb-4">
            <h2>BEST SELLING PRODUCTS</h2>
            <img src={StylishBorder} alt="" />
          </div>
          {bestSellingData.length > 0 ? (
            <ReactOwlCarousel
              className="owl-main owl-theme mt-5"
              nav
              navText={["", ""]}
              dots={false}
              margin={30}
              items={bestSellingData?.length}
              responsive={{
                0: {
                  center: true,
                  items: 1,
                  nav: false,
                },
                450: {
                  center: false,
                  items: 2,
                  nav: false,
                },
                992: {
                  center: false,
                  items: 3,
                  nav: false,
                },
                1024: {
                  center: false,
                  items: 2,
                  nav: true,
                },
                1920: {
                  center: false,
                  items: 4,
                  nav: true,
                },
              }}
              loop
            >
              {bestSellingData.map((data, index) => (
                <div key={index} className="item text-center">
                  <Link to={`/productdetail/${data?.slug}`}>
                    <img src={data.product_image} alt="" />
                  </Link>
                  <div className="content position-relative">
                    <Link>
                      <p className="m-0">{data?.name}</p>
                    </Link>
                  </div>
                </div>
              ))}
            </ReactOwlCarousel>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default BestSellingProduct;
