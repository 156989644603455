import React from 'react';
import { useSelector } from 'react-redux';
import "./../../../Assets/css/New york/home.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerSlider from './Home/Slider';
import CreateOwnDiamond from './Home/CreateOwnDiamond';
import OurCollection from './Home/OurCollection';
import { ShopDiamondShape } from './Home/ShopDiamondShape';
import WhatPeopleSay from './Home/WhatPeopleSay';
import { InstagramFeed } from './Home/InstagramFeed';
import { Newsletter } from './Home/Newsletter';
import BestSellingProduct from './Home/BestSellingProduct';
import { Helmet } from 'react-helmet';
import MacauSlider from './Home/MacauSlider';

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  return (
   
    <>
     <Helmet>
      <meta charSet="utf-8" />
      <title>{metaDetails.title}</title>
      <meta name="description" content={metaDetails.description}></meta>
      <meta name="keywords" content={metaDetails.keywords}></meta>
      {/*Og Tags */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaDetails.title} />
      <meta property="og:description" content={metaDetails.description} />
      <meta property="og:url" content={metaDetails.url} />
      <meta property="og:site_name" content="Arman Jwelers" />
    </Helmet>
      {/* <Header/> */}
      {/* <BannerSlider /> */}
      <MacauSlider/>
      <CreateOwnDiamond /> 
      <OurCollection />
      <BestSellingProduct />
      <ShopDiamondShape />
      <WhatPeopleSay />
      {/* <InstagramFeed /> */}
      <Newsletter />
    </> 
  )
}

export default Home;