import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
    name:'home',
    initialState:{
        currency:[],
        session_id:'',
        currencycode:'',
        currencyicon:'',
        currencyflag:'',
        menu:[]
    },
    reducers:{
        sessionId:(state,action)=>{
            state.session_id = action.payload
        },
        currency:(state,action) => {
            state.currency = action.payload
            if(state.currencycode == ''){
            state.currencycode = action.payload[0].currency_code
            }
            if(state.currencyicon == ''){
            state.currencyicon = action.payload[0].currency_icon
            }
            if(state.currencyflag == ''){
            state.currencyflag = action.payload[0].country_flag
            }
        },
        currencyicon:(state,action)=>{
            state.currencyicon = action.payload
        },
        currencycode:(state,action) => {
            state.currencycode = action.payload
        },
        currencyflag:(state,action) =>{
            state.currencyflag = action.payload
        },
        menu:(state,action) =>{
            state.menu = action.payload
        }
    }
})
export const {sessionId,currency,currencyicon,currencycode,currencyflag,menu} = homeSlice.actions
export default homeSlice.reducer