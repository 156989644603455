import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useHistory } from 'react-router-dom';
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
import axios from 'axios';
import { base_url, postHeader } from '../../../../Helpers/request';
import { toast } from 'react-toastify';
import video from "../../../../Assets/images/home-banner.mp4"

// import S1 from "../../../../Assets/images/sl1.jpg";

let sliderData = [
  // {
  //   img: isMobileOnly ? require('../../../../Assets/images/Engagement-Ring-Magnolia-Mobile.jpg') : require('../../../../Assets/images/sl1.jpg'),
  //   imgUrl: "/engagement-rings"
  // },
  // {
  //   img: isMobileOnly ? require('../../../../Assets/images/Fine-Jewelry-Mahnolia-Mobile.jpg') : require('../../../../Assets/images/sl2.jpg'),
  //   imgUrl: "/jewelry"
  // },
  // {
  //   img: isMobileOnly ? require('../../../../Assets/images/Wedding-Ring-Magnolia-Mobile.jpg') : require('../../../../Assets/images/sl3.jpg'),
  //   imgUrl: "/wedding-anniversary-bands"
  // }
]

const MacauSlider = () => {
  const history = useHistory();
  const [banner,setBanner] = useState([]);

  useEffect(()=>{
 //-----------------------------------Banner
 axios.get(base_url + `/home/slider`, {
  headers: postHeader
})
  .then(response => {
    if (response.data.status == 1) {
      setBanner(response.data.data);
      localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  })
  .catch((error) => {
    console.log(error);
  });
  },[])

  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    accessibility: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        }
      },
    ]

  };

    console.log('banner', banner)
  return (
    <>


      <Slider {...settings} className='overflow-hidden bannerSliderHome macau_banner_slider'>
          <div>
            <video src={video} autoPlay muted loop className='w-100'>
              Your browser does not support the video tag.
            </video>
          </div>
        {sliderData?.map((val, index) =>
          <div key={index}>
            <Link to={val?.imgUrl}>
              <img src={val?.img} alt="slider" className='img-fluid' />
            </Link>
          </div>
        )}
      </Slider>


      {/* <img src={S1} /> */}

    </>
  )
}

export default MacauSlider;